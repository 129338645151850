import PropTypes from "prop-types";

export const FetchActions = {
  None: "none", // do not refresh on componentDidMount
  List: "list", // list of resources
  Read: "read" // single resource
};

export const FetchStates = {
  Preloaded: "Preloaded",
  Fetching: "Fetching",
  Fetched: "Fetched",
  FetchError: "FetchError"
};

export const fetchStatePropType = PropTypes.oneOf(Object.values(FetchStates));

export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

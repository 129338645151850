import React from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Typography } from "@material-ui/core";
import classnames from "classnames";

const propTypes = {
  breadcrumbComponent: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  heading: PropTypes.node
};

const useStyles = makeStyles((_theme) => ({
  pageHeaderRoot: {
    display: "flex",
    flexDirection: "column"
  },
  titleRoot: {}
}));

export default function PageHeader({
  heading,
  breadcrumbComponent,
  children,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Box
      className={classnames(classes.pageHeaderRoot, "page-header")}
      mb={8}
      {...rest}
    >
      {breadcrumbComponent && <Box mb={8}>{breadcrumbComponent}</Box>}

      <Typography
        variant="h1"
        className={classnames(classes.titleRoot, "title")}
      >
        {heading}
      </Typography>

      {children}
    </Box>
  );
}
PageHeader.propTypes = propTypes;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import {
  FetchActions,
  FetchStates,
  fetchStatePropType,
  checkStatus
} from "./util/helpers";
import { idPropType } from "../util/customPropTypes";
import { noop } from "util";

export { FetchActions, FetchStates, fetchStatePropType, checkStatus };

import { compose } from "redux";
import { connect } from "react-redux";
import {
  addMemberToCacheAction,
  addCollectionToCacheAction,
  removeFromCacheAction
} from "../reducers/DB";

const FAILURE_AUTO_HIDE_DURATION = 5000;
const SUCCESS_AUTO_HIDE_DURATION = FAILURE_AUTO_HIDE_DURATION / 2;

export function withResource({
  ModelClass,
  dataKey, // TODO: rename to propName or propKey
  fetchStatePropKey = "fetchState", // useful for multi-wrapped components
  forceRefresh = false,
  // nextRefreshDelay, a function, returns a time, in seconds, to delay until
  // refresh is called again
  nextRefreshDelay = null,
  // propsMapper, a function, allows you to convert the props that your
  // Component requires the the ones that withResource requires (typically
  // just id, but if your component takes a resource, _also_ requiring
  // resourceId is a pain)
  propsMapper,
  refreshMethod = FetchActions.List,
  refreshOnMount = true,
  promiseCatchHandler = undefined
}) {
  let dataPropType;

  if (refreshMethod === FetchActions.Read) {
    dataPropType = PropTypes.object;
  } else if (refreshMethod === FetchActions.List) {
    dataPropType = PropTypes.array;
  } else {
    // it would be nice to assume object for member routes and array for
    // collection routes, but sometimes collection routes don't work that way
    dataPropType = PropTypes.any;
  }

  function combineProps(props) {
    if (!propsMapper) {
      return props;
    }
    return { ...props, ...propsMapper(props) };
  }

  return (WrappedComponent) => {
    const displayName = `withResource(${
      WrappedComponent.displayName || WrappedComponent.name
    })`;

    const C = class extends Component {
      static displayName = displayName;
      /* eslint-disable react/sort-prop-types */
      static propTypes = {
        [dataKey]: dataPropType,
        isPartialData: PropTypes.bool,
        id: idPropType,
        // defined below in the redux connection
        addCollectionToCache: PropTypes.func.isRequired,
        addMemberToCache: PropTypes.func.isRequired,
        removeFromCache: PropTypes.func.isRequired,
        // provided by ReactRouter
        history: PropTypes.object,
        location: PropTypes.object,
        // provided by withSnackBar
        enqueueSnackbar: PropTypes.func.isRequired,
        closeSnackbar: PropTypes.func.isRequired
      };
      /* eslint-enable react/sort-prop-types */

      constructor(props = {}) {
        super(props);

        const combinedProps = combineProps(props);
        this.model = new ModelClass(combinedProps);

        const { [dataKey]: data } = combinedProps;

        this.state = {
          fetchState: data ? FetchStates.Preloaded : undefined,
          [dataKey]: data
        };

        this._isStillMounted = false;
      }

      componentDidMount() {
        this._isStillMounted = true;

        this.initialRefresh();
      }

      componentDidUpdate(prevProps) {
        this.model.updateAttributes(combineProps(this.props));

        const isCollection = refreshMethod === FetchActions.List;
        const prevCacheKey = ModelClass.getCacheKey(prevProps, isCollection);
        const currCacheKey = ModelClass.getCacheKey(this.props, isCollection);

        if (currCacheKey === prevCacheKey) {
          return;
        }

        this.initialRefresh();
      }

      componentWillUnmount() {
        this._isStillMounted = false;
        this.model.abort();
        this.handleCancelAutoRefresh();
      }

      _applyDelayedRefresh = (...args) => {
        return (props) => {
          this.nextRefreshDelayInSeconds = nextRefreshDelay(
            props,
            this.nextRefreshDelayInSeconds
          );

          if (!this.nextRefreshDelayInSeconds) {
            return;
          }

          const refresh = () => this.refresh(...args);
          this.refreshTimer = setTimeout(
            refresh,
            this.nextRefreshDelayInSeconds
          );

          const nextRefreshAt = new Date(
            new Date().getTime() + this.nextRefreshDelayInSeconds
          );
          if (this._isStillMounted) {
            this.setState({ nextRefreshAt });
          }
        };
      };

      _getId = () => {
        const { id } = combineProps(this.props);

        return id;
      };

      initialRefresh() {
        const { [dataKey]: data, isPartialData } = this.props;

        if (data && !isPartialData && forceRefresh !== true) return;

        if (!refreshOnMount) return;

        this.refresh(null, { showErrorMessage: false });
      }

      getStandardHandlers(onSuccess, onFailure) {
        const successHandler = (data) => {
          if (this._isStillMounted) {
            this.setState({
              fetchState: FetchStates.Fetched
            });
          }

          if (onSuccess) {
            onSuccess(data);
          }

          return data;
        };

        const errorHandler = (fetchError) => {
          if (this._isStillMounted) {
            this.setState({
              fetchState: FetchStates.FetchError,
              fetchError
            });
          }

          if (onFailure) {
            onFailure(fetchError);
          }

          throw fetchError;
        };

        return [successHandler, errorHandler];
      }

      fnShowSuccessMessage(showMessage, message, messageOptions) {
        return (response) => {
          if (showMessage) {
            this.handleShowMessage(message, "success", {
              autoHideDuration: SUCCESS_AUTO_HIDE_DURATION,
              ...messageOptions
            });
          } else {
            this.handleCloseSnackbarIfKeyProvided(messageOptions);
          }

          return response;
        };
      }

      fnShowErrorMessage(showMessage, messageOptions) {
        return (fetchError) => {
          if (showMessage) {
            this.handleShowMessage(fetchError.message, "error", {
              autoHideDuration: FAILURE_AUTO_HIDE_DURATION,
              ...messageOptions
            });
          } else {
            this.handleCloseSnackbarIfKeyProvided(messageOptions);
          }

          throw fetchError;
        };
      }

      refresh = (...args) => {
        let promise;

        if (this.refreshTimer) {
          clearTimeout(this.refreshTimer);
          this.refreshTimer = null;
        }

        if (ModelClass.hasMemberRoute(refreshMethod)) {
          promise = this.handleMemberMethod(
            ModelClass.Method.Get,
            refreshMethod,
            ...args
          );
        } else if (ModelClass.hasCollectionRoute(refreshMethod)) {
          promise = this.handleCollectionMethod(
            ModelClass.Method.Get,
            refreshMethod,
            ...args
          );
        } else {
          if (refreshMethod === FetchActions.Read) {
            promise = this.handleReadResource(...args);
          } else if (refreshMethod === FetchActions.List) {
            promise = this.handleListResources(...args);
          }
        }

        if (!promise || !nextRefreshDelay) {
          return promise;
        }

        return promise.then(this._applyDelayedRefresh(...args));
      };

      setFetchingState() {
        const fetchState = FetchStates.Fetching;

        if (this._isStillMounted) {
          this.setState({ fetchState });
        }
      }

      // TODO: move this to a prop provided by some top-level provider
      handleShowMessage = (message, variant, messageOptions) => {
        const { replaceSnackbarKey, ...snackbarOptions } = messageOptions || {};

        const { closeSnackbar, enqueueSnackbar } = this.props;

        if (replaceSnackbarKey) closeSnackbar(replaceSnackbarKey);

        return enqueueSnackbar(message, { variant, ...snackbarOptions });
      };

      handleCloseSnackbarIfKeyProvided({ replaceSnackbarKey }) {
        const { closeSnackbar } = this.props;

        if (!replaceSnackbarKey) return;

        closeSnackbar(replaceSnackbarKey);
      }

      handleCancelAutoRefresh = () => {
        if (!this.refreshTimer) {
          return;
        }

        clearTimeout(this.refreshTimer);
        this.refreshTimer = null;
      };

      handleListResources = (
        params,
        {
          onSuccess,
          onFailure,
          replaceSnackbarKey,
          showSuccessMessage = false,
          showErrorMessage = true
        } = {}
      ) => {
        const { addCollectionToCache } = this.props;

        this.setFetchingState();

        const [successHandler, errorHandler] = this.getStandardHandlers(
          onSuccess,
          onFailure
        );

        return this.model
          .list(params)
          .then(successHandler)
          .then(addCollectionToCache)
          .then(
            this.fnShowSuccessMessage(showSuccessMessage, "Listed", {
              replaceSnackbarKey
            })
          )
          .catch(promiseCatchHandler)
          .catch(errorHandler)
          .catch(
            this.fnShowErrorMessage(showErrorMessage, { replaceSnackbarKey })
          );
      };

      handleCreateResource = (
        formData,
        {
          onSuccess,
          onFailure,
          replaceSnackbarKey,
          showSuccessMessage = true,
          showErrorMessage = true
        } = {}
      ) => {
        const { addMemberToCache } = this.props;

        this.setFetchingState();

        const [successHandler, errorHandler] = this.getStandardHandlers(
          onSuccess,
          onFailure
        );

        return this.model
          .create(formData)
          .then(successHandler)
          .then(addMemberToCache)
          .then(
            this.fnShowSuccessMessage(showSuccessMessage, "Created", {
              replaceSnackbarKey
            })
          )
          .catch(errorHandler)
          .catch(
            this.fnShowErrorMessage(showErrorMessage, { replaceSnackbarKey })
          );
      };

      handleReadResource = (
        params,
        {
          onSuccess,
          onFailure,
          replaceSnackbarKey,
          showSuccessMessage = false,
          showErrorMessage = true
        } = {}
      ) => {
        const { addMemberToCache } = this.props;

        this.setFetchingState();

        const [successHandler, errorHandler] = this.getStandardHandlers(
          onSuccess,
          onFailure
        );

        return this.model
          .read(this._getId(), params)
          .then(successHandler)
          .then(addMemberToCache)
          .then(
            this.fnShowSuccessMessage(showSuccessMessage, "Read", {
              replaceSnackbarKey
            })
          )
          .catch(promiseCatchHandler)
          .catch(errorHandler)
          .catch(
            this.fnShowErrorMessage(showErrorMessage, { replaceSnackbarKey })
          );
      };

      handleUpdateResource = (
        formData,
        {
          onSuccess,
          onFailure,
          replaceSnackbarKey,
          showSuccessMessage = true,
          showErrorMessage = true
        } = {}
      ) => {
        const { addMemberToCache } = this.props;

        this.setFetchingState();

        const [successHandler, errorHandler] = this.getStandardHandlers(
          onSuccess,
          onFailure
        );

        return this.model
          .update(this._getId(), formData)
          .then(successHandler)
          .then(addMemberToCache)
          .then(
            this.fnShowSuccessMessage(showSuccessMessage, "Updated", {
              replaceSnackbarKey
            })
          )
          .catch(errorHandler)
          .catch(
            this.fnShowErrorMessage(showErrorMessage, { replaceSnackbarKey })
          );
      };

      handleDestroyResource = ({
        onSuccess,
        onFailure,
        replaceSnackbarKey,
        showSuccessMessage = true,
        showErrorMessage = true
      } = {}) => {
        const { removeFromCache } = this.props;
        const id = this._getId();

        this.setFetchingState();

        const [successHandler, errorHandler] = this.getStandardHandlers(
          onSuccess,
          onFailure
        );

        return this.model
          .destroy(id)
          .then(successHandler)
          .then(() => removeFromCache({ id }))
          .then(
            this.fnShowSuccessMessage(showSuccessMessage, "Deleted", {
              replaceSnackbarKey
            })
          )
          .catch(errorHandler)
          .catch(
            this.fnShowErrorMessage(showErrorMessage, { replaceSnackbarKey })
          );
      };

      // for custom endpoints (using rails `member` route method)
      handleMemberMethod = (
        method,
        memberMethod,
        params,
        {
          onSuccess,
          onFailure,
          replaceSnackbarKey,
          showSuccessMessage = false,
          showErrorMessage = true
        } = {}
      ) => {
        this.setFetchingState();

        const [successHandler, errorHandler] = this.getStandardHandlers(
          onSuccess,
          onFailure
        );

        return this.model
          .member(method, memberMethod, this._getId(), params)
          .then(successHandler)
          .then(
            this.fnShowSuccessMessage(showSuccessMessage, "Done", {
              replaceSnackbarKey
            })
          )
          .catch(errorHandler)
          .catch(
            this.fnShowErrorMessage(showErrorMessage, { replaceSnackbarKey })
          );
      };

      handleCollectionMethod = (
        method,
        collectionMethod,
        params,
        {
          onSuccess,
          onFailure,
          replaceSnackbarKey,
          showSuccessMessage = false,
          showErrorMessage = true,
          shouldCache = true
        } = {}
      ) => {
        const { addCollectionToCache } = this.props;

        this.setFetchingState();

        const [successHandler, errorHandler] = this.getStandardHandlers(
          onSuccess,
          onFailure
        );

        return this.model
          .collection(method, collectionMethod, params)
          .then(successHandler)
          .then(shouldCache ? addCollectionToCache : noop)
          .then(
            this.fnShowSuccessMessage(showSuccessMessage, "Done", {
              replaceSnackbarKey
            })
          )
          .catch(errorHandler)
          .catch(
            this.fnShowErrorMessage(showErrorMessage, { replaceSnackbarKey })
          );
      };

      render() {
        const { fetchState, fetchError, nextRefreshAt } = this.state;
        const {
          /* eslint-disable react/prop-types */
          addMemberToCache: addMemberToCacheIgnored, // NOSONAR
          addCollectionToCache: addCollectionToCacheIgnored, // NOSONAR
          removeFromCache: removeFromCacheIgnored, // NOSONAR
          staticContext: staticContextIgnored, // NOSONAR
          closeSnackbar: closeSnackbarIgnored, // NOSONAR
          enqueueSnackbar: enqueueSnackbarIgnored, // NOSONAR
          ...providedProps
          /* eslint-enable react/prop-types */
        } = this.props;

        const props = {
          ...providedProps, // combineProps?
          [fetchStatePropKey]: fetchState,
          fetchError,
          isLoading: fetchState === FetchStates.Fetching,
          nextRefreshAt
        };

        return (
          <WrappedComponent
            {...props}
            onRefresh={this.refresh}
            onCancelAutoRefresh={this.handleCancelAutoRefresh}
            onListResources={this.handleListResources}
            onCreateResource={this.handleCreateResource}
            onReadResource={this.handleReadResource}
            onUpdateResource={this.handleUpdateResource}
            onDestroyResource={this.handleDestroyResource}
            onMemberMethod={this.handleMemberMethod}
            onCollectionMethod={this.handleCollectionMethod}
            onShowMessage={this.handleShowMessage}
          />
        );
      }
    };

    function mapStateToProps(state, ownProps) {
      let data;
      let isPartialData;
      let suffix;

      if (!dataKey) {
        return {};
      }

      if (
        ModelClass.hasMemberRoute(refreshMethod) ||
        ModelClass.hasCollectionRoute(refreshMethod)
      ) {
        suffix = refreshMethod;
      }

      const combinedProps = combineProps(ownProps);

      if (
        refreshMethod === FetchActions.Read ||
        ModelClass.hasMemberRoute(refreshMethod)
      ) {
        const cacheKey = ModelClass.getCacheKey(combinedProps, false, suffix);
        data = state.DB[cacheKey];

        if (data) {
          isPartialData = false;
        } else {
          const listCacheKey = ModelClass.getCacheKey(
            combinedProps,
            true,
            suffix
          );
          const cachedData = state.DB[listCacheKey] || [];
          if (Array.isArray(cachedData)) {
            data = cachedData.find(
              (r) => String(r.id) === String(combinedProps.id)
            );
          } else {
            // this is typically for collection routes which return an object.
            // maybe they shouldn't? idk.
            data = cachedData;
          }
          isPartialData = !!data;
        }
      } else {
        const cacheKey = ModelClass.getCacheKey(combinedProps, true, suffix);
        data = state.DB[cacheKey];
        isPartialData = false;
      }

      if (!data) {
        ({ [dataKey]: data } = combinedProps);
        isPartialData = false; // NOSONAR - will already be false, but kept for clarity
      }

      return {
        [dataKey]: data,
        isPartialData
      };
    }

    function mapDispatchToProps(dispatch, ownProps) {
      const combinedProps = combineProps(ownProps);
      let suffix;

      if (
        ModelClass.hasMemberRoute(refreshMethod) ||
        ModelClass.hasCollectionRoute(refreshMethod)
      ) {
        suffix = refreshMethod;
      }

      const getCacheKey = (isCollection) =>
        ModelClass.getCacheKey(combinedProps, isCollection, suffix);

      return {
        addMemberToCache: addMemberToCacheAction(dispatch, getCacheKey),
        addCollectionToCache: addCollectionToCacheAction(dispatch, getCacheKey),
        removeFromCache: removeFromCacheAction(dispatch, getCacheKey)
      };
    }

    return compose(
      connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true }),
      withSnackbar
    )(C);
  };
}

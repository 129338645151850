import React, { Component } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";

const styles = (theme) => ({
  action: {
    borderTop: "1px solid",
    borderTopColor: theme.palette.divider,
    display: "flex",
    justifyContent: "flex-end"
  },

  itemGrid: {
    display: "flex" // for full height of adjacent blocks
  },

  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%"
  },

  withGutters: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },

  withoutGutters: {
    padding: 0
  }
});

export class BlockWrapperComponent extends Component {
  static propTypes = {
    action: PropTypes.node,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    disableGutters: PropTypes.bool,
    elevation: PropTypes.number,
    item: PropTypes.bool
  };

  static defaultProps = {
    disableGutters: false,
    elevation: 1,
    item: false
  };

  render() {
    let actionContent;
    const {
      action,
      children,
      classes,
      className: providedClassName = "",
      elevation,
      item,
      disableGutters,
      ...gridItemProps
    } = this.props;

    const className = classNames(providedClassName, classes.itemGrid, {
      [classes.withGutters]: !disableGutters,
      [classes.withoutGutters]: disableGutters
    });

    if (action) {
      actionContent = <div className={classes.action}>{action}</div>;
    }

    const block = (
      <Grid item className={className} {...gridItemProps}>
        <Paper className={classes.paper} elevation={elevation}>
          {children}

          {actionContent}
        </Paper>
      </Grid>
    );

    if (item) {
      return block;
    }

    return <Grid container>{block}</Grid>;
  }
}

export default withStyles(styles)(BlockWrapperComponent);
